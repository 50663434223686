<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="flex-grow-1 overflow-y-auto">
        <v-img
          alt="Kenos"
          contain
          min-width="100"
          :src="kenosLogo"
          width="150"
        />
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <v-container class="fill-height pa-0" fluid>
        <chat />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Chat from "./components/Chat";
export default {
  name: 'App',

  components: {
    Chat,
  },

  data: () => ({
    kenosLogo: process.env.VUE_APP_BASE_URL + '/kenos.png'
  }),
};
</script>

<style>
.v-application .primary {
  background: #00957C !important;
}

a {
  color: yellow !important;
}
</style>