<template>
  <v-responsive
      class="overflow-y-hidden fill-height"
      :height="windowSize.y - 65"
  >
    <v-card flat class="d-flex flex-column fill-height" ref="mainContainer">
      <v-card-text class="flex-grow-1 overflow-y-auto" id="mainContainer">
        <template v-for="(msg, i) in messages">
          <div
              :class="{ 'd-flex flex-row-reverse': msg.me }"
              :key="`item-${i}`"
          >
            <v-chip
                :color="msg.me ? 'primary' : ''"
                dark
                style="height:auto;white-space: normal;"
                class="pa-4 mb-2"
            >
              <div v-html="msg.content"></div>
              <sub
                  class="ml-2"
                  style="font-size: 0.5rem;"
              >{{ msg.created_at }}</sub>
            </v-chip>
          </div>
        </template>
        <v-progress-circular
            ref="loader"
            v-if="loading"
            class="float-right"
            indeterminate
            color="primary"
        ></v-progress-circular>
        <div id="bottomelement">&nbsp;</div>
      </v-card-text>
      <v-card-text class="flex-shrink-1">
        <v-text-field
            v-model="messageForm.content"
            label="Escribe tu mensaje"
            type="text"
            no-details
            outlined
            append-outer-icon="mdi-send"
            @keyup.enter="sendMessage"
            @click:append-outer="sendMessage"
            hide-details
            autofocus
        />
      </v-card-text>
    </v-card>
  </v-responsive>
</template>

<script>
import { uuid } from 'vue-uuid'
import { mapState, mapMutations } from 'vuex'

export default {
  name: "Chat",
  data () {
    return {
      messages: [
        {
          content: "¿En que te puedo ayudar?",
          me: false,
          created_at: this.$moment().format('h:mm a')
        },
      ],
      messageForm: {
        content: "",
        me: true,
        created_at: "11:11am"
      },
      windowSize: {
        x: 0,
        y: 0
      },
      loading: false,
      namespace: '6ba7b811-9dad-11d1-80b4-00c04fd430c8'
    }
  },
  computed: {
    ...mapState([
        'deviceToken'
    ])
  },
  mounted() {
    if (!this.deviceToken || this.deviceToken == '') {
      let token = uuid.v5(uuid.v4(), this.namespace)
      this.setDeviceToken(token)
    }
    this.onResize()
  },
  methods: {
    ...mapMutations({
      setDeviceToken: 'SET_DEVICE_TOKEN'
    }),
    onResize () {
      this.windowSize = {
        x: window.innerWidth,
        y: window.innerHeight
      }
    },
    async sendMessage () {
      if (this.messageForm.content.length > 0)
      {
        this.loading = true

        this.messages.push({
          content: this.messageForm.content,
          me: true,
          created_at: this.$moment().format('h:mm a')
        })

        this.scrollChatBox()

        const message = this.messageForm.content

        this.messageForm = {
          content: ""
        }

        if (await this.prepareMessage()) {
          await this.$http.get(route(this.apiMsg(message)))
              .then(response => {
                if (response.status === 200) {
                  this.messages.push({
                    content: response.body,
                    me: false,
                    created_at: this.$moment().format('h:mm a')
                  })
                } else {
                  this.$alert(`Ha ocurrido un error ${response.statusText}`)
                }
              }, error => {
                this.$alert(`Ha ocurrido un error`)
              })
        }

        this.scrollChatBox(true)
      }
    },
    scrollChatBox (hideLoader = false) {
      this.$scrollTo('#bottomelement', 500, {
        container: '#mainContainer',
        onDone: () => {
          if (hideLoader) this.loading = false
        }
      })
    },
    async prepareMessage () {
      let control = false

      await this.$http.get(route('getip/'+this.deviceToken))
      .then(response => {
        control = true
        console.log(response)
      }, error => {
        this.$alert('Ha ocurrido un error')
      })

      return control
    }
  }
}
</script>

<style>
  .v-chip__content img {
    display: block;
    text-align: center;
    margin: 0 auto;
    height: auto !important;
    min-width: 500px !important;
    padding: 15px;
  }
</style>