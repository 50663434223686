import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const theme = {
    primary: '#00957C',
    secondary: '#1A1413',
    accent: '#00957C',
    info: '#00CAE3',
}

export default new Vuetify({
    theme: {
        themes: {
            dark: theme,
            light: theme
        }
    }
});
