import Vue from 'vue'

Vue.mixin({
    methods: {
        apiMsg (msg = '') {
            return `get?msg=${msg}`
        },
        showError (error = false) {

        }
    }
})