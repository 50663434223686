import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueMoment from 'vue-moment'
import VueResource from 'vue-resource'
import VueSimpleAlert from "vue-simple-alert"
import VueScrollTo from 'vue-scrollto'

import store from './store'
import './plugins/mixins'

Vue.use(VueMoment)
Vue.use(VueResource)
Vue.use(VueSimpleAlert)
Vue.use(VueScrollTo)

Vue.config.productionTip = false

window.route = (method, _endpoint = '') => {
  const endpoint = _endpoint ? _endpoint : process.env.VUE_APP_API_URL
  return [endpoint, method].join('/')
}

new Vue({
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')