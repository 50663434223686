import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

export default new Vuex.Store({
    state: {
        deviceToken: false
    },
    mutations: {
        SET_DEVICE_TOKEN (state, payload) {
            state.deviceToken = payload
        }
    },
    actions: {},
    plugins: [vuexLocal.plugin]
})